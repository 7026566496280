import { createRouter, createWebHistory } from 'vue-router'

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue')
	},
	{
		path: '/participate',
		name: 'Participate',
		component: () => import(/* webpackChunkName: "Participate" */ '../views/Participate.vue')
	},
	{
		path: '/gifts',
		name: 'Gifts',
		component: () => import(/* webpackChunkName: "Gifts" */ '../views/Gifts.vue')
	},
	{
		path: '/winners',
		name: 'Winners',
		component: () => import(/* webpackChunkName: "Winners" */ '../views/Winners.vue')
	},
	{
		path: '/cookies',
		name: 'Cookies',
		component: () => import(/* webpackChunkName: "Contact" */ '../views/Cookies.vue')
	},
	{
		path: '/contact',
		name: 'Contact',
		component: () => import(/* webpackChunkName: "Contact" */ '../views/Contact.vue')
	},
	{
		path: '/finish',
		name: 'Finish',
		component: () => import(/* webpackChunkName: "Finish" */ '../views/Finish.vue')
	},
	{
		path: '/terms',
		name: 'Terms',
		component: () => import(/* webpackChunkName: "Finish" */ '../views/Terms.vue')
	}
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
})

export default router
