<template>
<div id="cookie-consent">
	<div v-if="showCookie" class="cookie-popup">
		<h1 v-html="title" class="title"></h1>
		<div v-html="description" class="description"></div>
		<div class="actions">
			<button class="btn btn-accept" v-on:click="agreeConsent">{{ acceptButton }}</button>
			<button class="btn btn-decline" v-if="hasDeclineButton" v-on:click="declineConsent">{{ declineButton }}</button>
			<button class="btn btn-advanced" v-if="hasAdvanced" v-on:click="toggleAdvanced">{{ changePrefsButton }}</button>
		</div>
	</div>
	<div v-if="showCookieAdvanced" class="cookie-popup-advanced">
	</div>
	<div v-on:click="toggleCookieConsent" v-if="!showCookie && !showCookieAdvanced" class="cookie-toggler">
		<svg width="40" height="40" viewBox="0 0 71.85 72.23" xmlns="http://www.w3.org/2000/svg"> <path d="m67.6 36.73a6.26 6.26 0 0 1 -3.2-2.8 5.86 5.86 0 0 0 -5.2-3.1h-.3a11 11 0 0 1 -11.4-9.5 6 6 0 0 1 -.1-1.4 9.2 9.2 0 0 1 .4-2.9 8.65 8.65 0 0 0 .2-1.6 5.38 5.38 0 0 0 -1.9-4.3 7.3 7.3 0 0 1 -2.5-5.5 3.91 3.91 0 0 0 -3.5-3.9 36.46 36.46 0 0 0 -15 1.5 33.14 33.14 0 0 0 -22.1 22.7 35.62 35.62 0 0 0 -1.5 10.2 34.07 34.07 0 0 0 4.8 17.6.75.75 0 0 0 .07.12c.11.17 1.22 1.39 2.68 3-.36.47 5.18 6.16 5.65 6.52a34.62 34.62 0 0 0 55.6-21.9 4.38 4.38 0 0 0 -2.7-4.74z" stroke-width="3" style="fill: rgb(255, 255, 255); stroke: rgb(41, 36, 106);"></path> <path d="m68 41.13a32.37 32.37 0 0 1 -52 20.5l-2-1.56c-2.5-3.28-5.62-7.15-5.81-7.44a32 32 0 0 1 -4.5-16.5 34.3 34.3 0 0 1 1.4-9.6 30.56 30.56 0 0 1 20.61-21.13 33.51 33.51 0 0 1 14.1-1.4 1.83 1.83 0 0 1 1.6 1.8 9.38 9.38 0 0 0 3.3 7.1 3.36 3.36 0 0 1 1.2 2.6 3.37 3.37 0 0 1 -.1 1 12.66 12.66 0 0 0 -.5 3.4 9.65 9.65 0 0 0 .1 1.7 13 13 0 0 0 10.5 11.2 16.05 16.05 0 0 0 3.1.2 3.84 3.84 0 0 1 3.5 2 10 10 0 0 0 4.1 3.83 2 2 0 0 1 1.4 2z" stroke-width="3" style="fill: rgb(255, 255, 255); stroke: rgb(41, 36, 106);"></path> <g style="fill: rgb(55, 205, 143);"> <path d="m26.6 31.43a5.4 5.4 0 1 1 5.4-5.43 5.38 5.38 0 0 1 -5.33 5.43z"></path> <path d="m25.2 53.13a5.4 5.4 0 1 1 5.4-5.4 5.44 5.44 0 0 1 -5.4 5.4z"></path> <path d="m47.9 52.33a5.4 5.4 0 1 1 5.4-5.4 5.32 5.32 0 0 1 -5.24 5.4z"></path> </g> </svg>
	</div>
</div>
</template>

<script>
const Cookies = require('tiny-cookie');

export default {
	name: 'CookieConsent',
	props: {
		cookieName: {
			type: String,
			default: "cookieConsent",
		},
		storageType: {
			type: String,
			default: 'cookies', // 'cookies', 'localstorage'
		},
		title: {
			type: String,
			default: "We use cookies",
		},
		description: {
			type: String,
			default: "We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from.",
		},
		acceptButton: {
			type: String,
			default: "I agree",
		},
		hasDeclineButton: {
			type: Boolean,
			default: true,
		},
		declineButton: {
			type: String,
			default: "I don't agree",
		},
		changePrefsButton: {
			type: String,
			default: "Change my preferences",
		},
		hasAdvanced: {
			type: Boolean,
			default: false,
		},
		necessaryCookies: {
			type: Boolean,
			default: true,
		},
		necessaryTitle: {
			type: String,
			default: "Strictly necessary cookies",
		},
		necessaryDescription: {
			type: String,
			default: "These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website.<br />Without these cookies, we cannot provide you certain services on our website.",
		},
		hasFunctionalityCookies: {
			type: Boolean,
			default: false,
		},
		functionalityCookies: {
			type: Boolean,
			default: false,
		},
		functionalityTitle: {
			type: String,
			default: "Functionality cookies",
		},
		functionalityDescription: {
			type: String,
			default: "These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.<br />For example, we may use functionality cookies to remember your language preferences or remember your login details.",
		},
		hasTrackingCookies: {
			type: Boolean,
			default: false,
		},
		trackingCookies: {
			type: Boolean,
			default: false,
		},
		trackingTitle: {
			type: String,
			default: "Tracking and performance cookies",
		},
		trackingDescription: {
			type: String,
			default: "These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.<br />For example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website site for you.<br />The information collected through these tracking and performance cookies do not identify any individual visitor.",
		},
		hasAdvertisingCookies: {
			type: Boolean,
			default: false,
		},
		advertisingCookies: {
			type: Boolean,
			default: false,
		},
		advertisingTitle: {
			type: String,
			default: "Targeting and advertising cookies",
		},
		advertisingDescription: {
			type: String,
			default: "These cookies are used to show advertising that is likely to be of interest to you based on your browsing habits.<br />These cookies, as served by our content and/or advertising providers, may combine information they collected from our website with other information they have independently collected relating to your web browser's activities across their network of websites.<br />If you choose to remove or disable these targeting or advertising cookies, you will still see adverts but they may not be relevant to you.",
		},
	},
	data() {
		return {
			showCookie: false,
			showCookieAdvanced: false,
			cookieObj: {
				enabled: null,
				advanced: false,
				necessary: true,
				functionality: false,
				tracking: false,
				advertising: false
			}
		}
	},
	watch: {
		'cookieObj.enabled': function() {
			this.cookieLogic();
		}
	},
	mounted() {
		var vm = this;
		if (!['cookies', 'localstorage'].includes(vm.storageType)) {
			vm.storageType = 'cookies'
		}
		vm.checkConsent();
	},
	methods: {
		checkConsent() {
			var vm = this;
			var consent = null;
			if (vm.storageType == 'cookies' && Cookies.isEnabled()) {
				consent = JSON.parse(Cookies.get(vm.cookieName));
				if (consent) vm.cookieObj = consent;
			} else if (vm.storageType == 'localstorage' || !Cookies.isEnabled()) {
				consent = JSON.parse(localStorage.getItem(vm.cookieName));
				if (consent) vm.cookieObj = consent;
			}
			if (consent && consent.enabled === true) {
				vm.showCookie = false;
			} else if (!consent || consent.enabled === null) {
				vm.showCookie = true;
			}
			vm.cookieLogic();
		},
		agreeConsent() {
			var vm = this;
			vm.cookieObj.enabled = true;
			if (vm.storageType == 'cookies' && Cookies.isEnabled()) {
				Cookies.set(vm.cookieName, JSON.stringify(vm.cookieObj), {expires: '1M', secure: true, samesite: 'None'});
			} else if (vm.storageType == 'localstorage' || !Cookies.isEnabled()) {
				localStorage.setItem(vm.cookieName, JSON.stringify(vm.cookieObj));
			}
			vm.showCookie = false;
			vm.cookieLogic();
		},
		declineConsent() {
			var vm = this;
			vm.cookieObj.enabled = false;
			if (vm.storageType == 'cookies' && Cookies.isEnabled()) {
				Cookies.set(vm.cookieName, JSON.stringify(vm.cookieObj), {expires: '1M', secure: true, samesite: 'None'});
			} else if (vm.storageType == 'localstorage' || !Cookies.isEnabled()) {
				localStorage.setItem(vm.cookieName, JSON.stringify(vm.cookieObj));
			}
			vm.showCookie = false;
			vm.cookieLogic();
		},
		cookieLogic() {
			var vm = this;
			if (!vm.cookieObj.advanced) {
				if (vm.cookieObj.enabled) {
					//vm.$gtag.set("enabled", true)
				} else {
					//vm.$gtag.set("enabled", false)
				}
			}
		},
		toggleCookieConsent() {
			var vm = this;
			vm.showCookie = !vm.showCookie;
		},
		toggleAdvanced() {
			var vm = this;
			vm.showCookieAdvanced = !vm.showCookieAdvanced;
		},
		saveAdvanced() {
		},
	}
}
</script>

<style scoped lang="scss">
#cookie-consent .cookie-popup { z-index: 3; position: fixed; bottom: 20px; left: 20px; width: 260px; max-width: 100%; background-color: #fff; padding: 30px; box-shadow: 0 0 10px rgba(0,0,0,0.2); color: #000; font-family: 'Roboto', sans-serif; }
#cookie-consent .cookie-popup h1 { color: #000; font-family: 'Roboto', sans-serif; }
.uk-light #cookie-consent .cookie-popup { background-color: #000; }
.cookie-toggler { z-index: 3; position: fixed; bottom: 20px; left: 20px; cursor: pointer; }
.title { font-size: 20px; }
.description { font-size: 14px; }
.actions { margin-top: 10px; }
.btn { border: 1px solid #000; padding: 8px 16px; margin-right: 10px; cursor: pointer; color: #000; }
.btn:hover { background-color: #000; color: #fff; }
.btn:last-child { margin-right: 0; }
.btn.btn-accept { background-color: #00b32d; color: #fff; }
.btn.btn-accept:hover { background-color: #000; color: #fff; }
.btn.btn-decline { background-color: #b30000; color: #fff; }
.btn.btn-decline:hover { background-color: #000; color: #fff; }
</style>
