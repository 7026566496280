import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

// loads the Icon plugin
UIkit.use(Icons);

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import { VueReCaptcha } from 'vue-recaptcha-v3'
//console.log(process.env.VUE_APP_RECAPTCHA_PUBLIC);

createApp(App).use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_PUBLIC }).use(store).use(router).mount('#app')
